import samosa from "../assets/images/samosa.jpg";
import meduvada from "../assets/images/meduvada.jpg";
import muttondosa from "../assets/images/muttondosa.jpg";
import muttonsukka from "../assets/images/muttonsukka.jpg";
import eggbiriyani from "../assets/images/eggbiriyani.jpg";
import plaindosa from "../assets/images/plaindosa.jpeg";
import chickendosa from "../assets/images/dosa.jpg";
import peppermutton from "../assets/images/peppermutton.JPG";
import muttonbiriyani from "../assets/images/muttonbiriyani.jpeg";
import chickenbiriyani from "../assets/images/chickenbiryani.jpg";
import eggpodimas from "../assets/images/eggpodimas.jpeg";
import pannertikkamasala from "../assets/images/pannertikkamasala.jpg";
import chennaichickencurry from "../assets/images/chennaichickencurry.jpg";
import chickentikka from "../assets/images/chickentikka.jpg";
import sambarvada from "../assets/images/sambarvada.jpg";
import chicken65 from "../assets/images/chicken65.jpg";
import fish65 from "../assets/images/fish65.jpg";
import malaikofta from "../assets/images/malaikofta.jpeg";
import muttonchettinad from "../assets/images/muttonchettinad.jpeg";
import gheedosa from "../assets/images/dosa.jpg";
import nellorebiriyani from "../assets/images/nellorebiriyani.jpg";
export const Dishes = [
  {
    foodName: "SAMOSA(2pcs)",
    quantity: 2,
    price: 7.0,
    rating: 4.0,
    description:
      "Crispy deep-fried pastry, filled with potatoes and green peas flavored with fresh spices",
    image: `url(${samosa})`,
    container: "box All",
  },
  {
    foodName: "MEDU VADA(2pcs)",
    quantity: 2,
    price: 7.0,
    rating: 4.0,
    description: "Deep fried flavorful savory lentil donut with herbs & spices",
    image: `url(${meduvada})`,
    container: "box All featured",
  },
  {
    foodName: "SAMBAR VADA(2pcs)",
    quantity: 2,
    price: 8.0,
    rating: 4.0,
    image: `url(${sambarvada})`,
    description:
      "Crispy lentil donut immersed in sambar garnished with onions & cilantro",
     container: "Special",
  },
  {
    foodName: "CHICKEN 65",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    image: `url(${chicken65})`,
    description:
      "Boneless chicken cube, marinated with Indian spices & deep fried",
    container: "box All",
  },
  {
    foodName: "MUTTON SUKKA",
    quantity: 1,
    price: 16.0,
    rating: 4.0,
    description: "A spicy dish with mutton cooked with flavorful Indian spices",
    image: `url(${muttonsukka})`,
    container: "box All",
  },
  {
    foodName: "MUTTON PEPPER DRY",
    quantity: 1,
    price: 16.0,
    rating: 4.0,
    description:
      "A dry preparation with lots of onions, curry leaves & freshly ground pepper & fennel",
    image: `url(${peppermutton})`,
    container: "box All",
  },
  {
    foodName: "FISH 65",
    quantity: 1,
    price: 15.0,
    rating: 4.0,
    image: `url(${fish65})`,
    description:
      "Crispy and delicious starter prepared using boneless fish marinated with spices and deep fried",
    container: "box All",
  },
  {
    foodName: "EGG PODIMAS(Burji)",
    quantity: 1,
    price: 8.0,
    rating: 4.0,
    image: `url(${eggpodimas})`,
    description:
      "An Indian dish made by scrambling eggs & cooking with spices.",
    container: "box All",
  },
  {
    foodName: "PANEER TIKKA MASALA",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    image: `url(${pannertikkamasala})`,
    description:
      "Grilled chunks of cottage cheese cooked in spicy-flavorful onion-tomato sauce",
     container: "box All",
  },
  {
    foodName: "BUTTER MASALA",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    description:
      "Cottage cheese cubes sautéed with butter and cooked in tomato sauce",
     container: "box All",
  },
  {
    foodName: "CHANNA MASALA",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    description: "Sweet and tangy masala with garbanzo beans",
    container: "box All",
  },
  {
    foodName: "CHENNAI CHICKEN CURRY",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    image: `url(${chennaichickencurry})`,
    description:
      "Madras style chicken curry made with roasted spices and coconut paste.",
    container: "box All",
  },
  {
    foodName: "CHICKEN TIKKA / BUTTER MASALA",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    image: `url(${chickentikka})`,
    description:
      "Chunks of roasted marinated chicken (Chicken Tikka) cooked with spiced creamy curry sauce.",
    container: "box All",
  },
  {
    foodName: "MALAI KOFTA",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    image: `url(${malaikofta})`,
    description:
      "Batter fried potato & paneer balls immersed in smooth, rich & creamy gravy",
    container: "box All",
  },
  {
    foodName: "MUTTON CHETTINAD",
    quantity: 1,
    price: 16.0,
    rating: 4.0,
    image: `url(${muttonchettinad})`,
    description: "Mutton cooked in a spicy & freshly grounded Chettinad sauce",
    container: "box All",
  },
  {
    foodName: "VEGETABLE DUM BIRYANI",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    description: "Fine cuts of vegetables, spiced and cooked with Basmati Rice",
    container: "box All",
  },
  {
    foodName: "DINDUGAL MUTTON DUM BIRYANI",
    quantity: 1,
    price: 16.0,
    rating: 4.0,
    description: "Spiced mix of goat meat and seeraga samba rice",
    image: `url(${muttonbiriyani})`,
    container: "box All",
  },
  {
    foodName: "EGG BIRYANI",
    quantity: 1,
    price: 14.0,
    rating: 4.0,
    description:
      "Egg Biryani is prepared by layering spices, boiled eggs, aromatic rice and cooked until done.",
    image: `url(${eggbiriyani})`,
    container: "box All",
  },
  {
    foodName: "DINDUGAL CHICKEN DUM BIRYANI",
    quantity: 1,
    price: 12.0,
    rating: 4.0,
    description: "Spiced mix of chicken meat and seeraga samba rice",
    image: `url(${chickenbiriyani})`,
    container: "box All",
  },
  {
    foodName: "NELLORE BONELESS CHICKEN BIRYANI",
    quantity: 1,
    price: 15.0,
    rating: 4.0,
    image: `url(${nellorebiriyani})`,
    description:
      "Delicious, spiced mix of basmati rice traditionally cooked with deep fried boneless chicken",
    container: "box All",
  },
  {
    foodName: "PLAIN DOSA",
    quantity: 1,
    price: 11.0,
    rating: 4.0,
    description: "Dosa is a thin flat crepe originating from South India",
    image: `url(${plaindosa})`,
    container: "box All",
  },
  {
    foodName: "GHEE DOSA",
    quantity: 1,
    price: 12.0,
    rating: 4.0,
    description: "Dosa is a thin flat crepe originating from South India",
    image: `url(${gheedosa})`,
    container: "box All",
  },
  {
    foodName: "EGG DOSA",
    quantity: 1,
    price: 12.0,
    rating: 4.0,
    description: "Dosa is a thin flat crepe originating from South India",
    container: "box All",
  },
  {
    foodName: "CHICKEN DOSA",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    description: "Dosa is a thin flat crepe originating from South India",
    image: `url(${chickendosa})`,
    container: "box All",
  },
  {
    foodName: "MUTTON DOSA",
    quantity: 1,
    price: 13.0,
    rating: 4.0,
    description: "Dosa is a thin flat crepe originating from South India",
    image: `url(${muttondosa})`,
    container: "box All",
  },
];
