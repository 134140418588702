
import chickentandori from "../assets/menuimages/starters/chickentandori.jpg";

import chickenlolipop from "../assets/menuimages/starters/chickenlollipop.jpg";
export const clayOven = [
  {
    category: "Clayoven",
    foodName: "CHICKEN TANDOORI",
    price: 15.0,
    description:
      "Succulent chicken marinated in a blend of yogurt and aromatic spices, then cooked in a clay tandoor oven for a smoky charred flavor and tender texture.",
    image: `url(${chickentandori})`,
  },
  {
    category: "Clayoven",
    foodName: "CHICKEN TIKKA KEBAB",
    price: 15.0,
    description:
      "Bite-sized pieces of tender chicken marinated in a flavorful blend of spices, skewered and grilled to perfection for a charred and irresistibly delicious appetizer.",
      image: `url(${chickenlolipop})`,
  },
  {
    category: "Clayoven",
    foodName: "FISH TIKKA",
    price: 16.0,
    description:
      "Fresh fish chunks marinated in a zesty mixture of spices and herbs, then skewered and grilled for a delightful smoky and tangy seafood delicacy.",
  },
  {
    category: "Clayoven",
    foodName: "SHRIMP TIKKA",
    price: 18.0,
    description:
      "Plump and succulent shrimp marinated in a rich medley of Indian spices, skewered and grilled to create a delightful fusion of flavors with a tantalizing smoky twist.",
  },
  {
    category: "Clayoven",
    foodName: "VEG PANEER TIKKA",
    price: 15.0,
    description:
      "Cubes of soft paneer (Indian cottage cheese) marinated in a zesty mixture of spices, skewered and grilled for a delightful vegetarian appetizer or entrée option.",
  },
];

export const riceNoodles = [
  {
    category: "Rice/Noodles",
    foodName: "Vegetarian FRIED RICE/noodles",
    price: 13.0,
    description:
      "A delicious, stir-fried dish featuring cooked rice/noodles tossed with an assortment of colorful vegetables, soy sauce, and seasonings, offering a savory and satisfying meal.",
  },
  {
    category: "Rice/Noodles",
    foodName: "Egg fried rice/noodles",
    price: 13.0,
    description:
      "A delightful dish featuring fluffy steamed rice/noodles stir-fried with scrambled eggs, vegetables, and savory soy sauce, creating a harmonious blend of textures and flavors.",
  },
  {
    category: "Rice/Noodles",
    foodName: "Chicken fried rice/noodles",
    price: 14.0,
    description:
      "Wok-tossed medley of cooked rice/noodles, tender chicken, assorted vegetables, and savory soy sauce, creating a flavorful and satisfying Asian-inspired dish.",
  },
  {
    category: "Rice/Noodles",
    foodName: "SHRIMP fried rice/noodles",
    price: 15.0,
    description:
      "Plump shrimp and seasoned vegetables wok-tossed with fluffy rice/noodles and soy sauce, creating a flavorful and satisfying Asian-inspired dish.",
  },
];

export const vegMainCourse = [
  {
    category: "Veg Main Course",
    foodName: "MALAI KOFTA",
    price: 13.0,
    description:
      "Delectable vegetarian dish featuring creamy milk and fried potatoes served in a rich tomato-based gravy, bursting with flavors of aromatic spices.",
  },
  {
    category: "Veg Main Course",
    foodName: "CHETTINAD mushroom/EGG PLANT MASALA",
    price: 13.0,
    description:
      "A spicy and flavorful South Indian dish featuring tender eggplant cooked with a rich blend of roasted spices, coconut, and tamarind for a tangy and aromatic delight.",
  },
  {
    category: "Veg Main Course",
    foodName: "MIXED VEG KURMA",
    price: 13.0,
    description:
      "A rich South Indian curry featuring an assortment of vegetables cooked in a creamy coconut, onion, and tomato-based sauce infused with fragrant spices.",
  },
  {
    category: "Veg Main Course",
    foodName: "SAMBAR",
    price: 12.0,
    description:
      "A flavorful South Indian lentil stew, prepared with Toor dal, vegetables, tamarind, and a blend of spices, offering a savory and tangy taste.",
  },
  {
    category: "Veg Main Course",
    foodName: "DAL FRY",
    price: 13.0,
    description:
      "A flavorful Indian lentil dish made by tempering cooked lentils with aromatic spices, onions, tomatoes, and herbs.",
  },
  {
    category: "Veg Main Course",
    foodName: "DAL PALAK",
    price: 13.0,
    description:
      "A nutritious Indian dish combining lentils and spinach, cooked to perfection with aromatic spices for a flavorful and wholesome meal.",
  },
  {
    category: "Veg Main Course",
    foodName: "KAJU PANEER",
    price: 13.0,
    description:
      "A rich and creamy Indian dish featuring paneer and cashews cooked in a luscious tomato-based gravy, offering a perfect balance of flavors and textures.",
  },
  {
    category: "Veg Main Course",
    foodName: "KADAI PANEER",
    price: 13.0,
    description:
      "Cubes of paneer cooked with colorful bell peppers, onions, and aromatic spices in a rich tomato-based gravy, creating a flavorful and hearty Indian dish.",
  },
  {
    category: "Veg Main Course",
    foodName: "PANEER TIKKA MASALA",
    price: 13.0,
    description:
      "Cubes of marinated paneer cheese grilled to perfection, served in a rich and creamy tomato-based curry infused with spices.",
  },
  {
    category: "Veg Main Course",
    foodName: "PANEER BUTTER MASALA",
    price: 13.0,
    description:
      "Creamy and rich tomato-based curry featuring soft paneer cheese, simmered in a luscious blend of buttery sauce and aromatic spices.",
  },
  {
    category: "Veg Main Course",
    foodName: "SAAG PANEER",
    price: 13.0,
    description:
      "A flavorful Indian dish featuring cubes of paneer cheese simmered in a vibrant spinach and fenugreek sauce, enriched with spices and cream.",
  },
  {
    category: "Veg Main Course",
    foodName: "CHANNA MASALA/SAAG Channa",
    price: 13.0,
    description:
      "A flavorful North Indian dish featuring tender chickpeas simmered in a rich onion and tomato-based sauce infused with a harmonious blend of spices.",
  },
];

export const chickenMainCourse = [
  {
    category: "Chicken Main Course",
    foodName: "Chicken Dhaba curry",
    price: 14.0,
    description:
      "Tender chicken pieces cooked in a rich and flavorful tomato-based gravy, infused with a medley of traditional spices, onions, and aromatic herbs, reminiscent of the vibrant roadside Dhaba eateries.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Andhra chicken curry",
    price: 14.0,
    description:
      "A fiery South Indian dish featuring tender chicken pieces cooked in a rich, spicy gravy made with a blend of red chilies and traditional Andhra spices.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Chicken chettinad",
    price: 14.0,
    description:
      "A rich South Indian dish featuring tender chicken simmered in a bold and spicy gravy infused with roasted spices, coconut, and curry leaves.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Chennai chicken curry",
    price: 14.0,
    description:
      "A rich and spicy Chennai-style dish featuring tender chicken cooked in a flavorful blend of coconut milk, tomatoes, and traditional spices, offering a balance of heat and depth of flavors.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Chicken kurma",
    price: 14.0,
    description:
      "South Indian style gravy cooked with tender chicken, aromatic spices, and coconut paste.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Chola’s special chicken varutha curry",
    price: 14.0,
    description:
      "Tender pieces of chicken slow cooked in a rich blend of Chola’s special roasted spices, coconut, and curry leaves, creating a flavorful and aromatic South Indian curry.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Chicken tikka masala",
    price: 14.0,
    description:
      "Grilled chicken pieces simmered in a creamy tomato-based sauce, richly spiced with a harmonious blend of Indian flavors.",
  },
  {
    category: "Chicken Main Course",
    foodName: "CHICKEN BUTTER MASALA",
    price: 14.0,
    description:
      "Tender chicken cooked in a rich and creamy tomato-based gravy, infused with aromatic spices, and finished with a luscious touch of butter, creating a delectable Indian comfort dish.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Kadai chicken",
    price: 14.0,
    description:
      "A flavorful Indian dish where tender chicken pieces are cooked with assorted bell peppers, onions, and aromatic spices in a 'kadai' (wok), creating a delectable blend of textures and tastes.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Saag chicken",
    price: 14.0,
    description:
      "A delightful Indian dish featuring tender pieces of chicken simmered in a vibrant and flavorful spinach-based gravy.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Chicken vindaloo",
    price: 14.0,
    description:
      "A fiery chicken dish made with onion, tomatoes, potato cubes, and fresh ground spices in Indo-Portuguese style.",
  },
  {
    category: "Chicken Main Course",
    foodName: "Kozhi milagu(Pepper chicken) masala",
    price: 14.0,
    description:
      "Chicken cooked with onion, tomato, and finished with fresh ground pepper.",
  },
];

export const muttonMainCourse = [
  {
    foodName: "CHOLA’S SPECIAL MUTTON MASALA",
    category: "Mutton Main Course",
    description:
      "A tantalizing blend of tender mutton pieces cooked in a rich, aromatic gravy infused with Chola Cafe's unique spice mix, creating a flavorful and indulgent dish.",
    price: 17.0,
  },
  {
    foodName: "MUTTON ROGAN JOSH",
    category: "Mutton Main Course",
    description:
      "A delectable Kashmiri curry featuring succulent mutton slow-cooked with a harmonious blend of aromatic spices, yogurt, and tomatoes, resulting in a rich and deeply flavorful dish.",
    price: 17.0,
  },
  {
    foodName: "CHENNAI MUTTON CURRY",
    category: "Mutton Main Course",
    description:
      "A South Indian delight where tender mutton is simmered in a luscious gravy crafted with traditional spices, coconut milk, and curry leaves, delivering a burst of authentic Chennai flavors.",
    price: 17.0,
  },
  {
    foodName: "MUTTON CHETTINAD",
    category: "Mutton Main Course",
    description:
      "A spicy and aromatic South Indian dish featuring tender mutton cooked in a bold Chettinad masala, blending roasted spices, coconut, and curry leaves for an unforgettable flavor experience.",
    price: 17.0,
  },
  {
    foodName: "MUTTON KURMA",
    category: "Mutton Main Course",
    description:
      "A luxurious Indian dish where tender mutton is simmered in a velvety, aromatic gravy enriched with fragrant spices, creating a harmonious balance of flavors.",
    price: 17.0,
  },
  {
    foodName: "MUTTON VINDALOO",
    category: "Mutton Main Course",
    description:
      "A fiery mutton dish made with onion, tomatoes, potato cubes, and fresh ground spices in Indo-Portuguese style.",
    price: 17.0,
  },
];

export const seafoodMainCourse = [
  {
    category: "Seafood Main Course",
    foodName: "PRAWN MASALA",
    price: 17.0,
    description:
      "Succulent prawns cooked in a fragrant and spicy masala gravy, crafted with aromatic spices and tomatoes, delivering a delightful and flavorful seafood dish.",
  },
  {
    category: "Seafood Main Course",
    foodName: "MADRAS FISH CURRY",
    price: 15.0,
    description:
      "A zesty South Indian dish featuring tender fish pieces simmered in a tangy and aromatic gravy infused with tamarind, coconut, and a blend of traditional spices, capturing the authentic flavors of Madras (Chennai) cuisine.",
  },
  {
    category: "Seafood Main Course",
    foodName: "CHENNAI EGG CURRY",
    price: 13.0,
    description:
      "A delicious South Indian delicacy showcasing three perfectly boiled eggs immersed in a rich and spiced gravy, prepared with a medley of aromatic ingredients, tomatoes, and coconut milk, reflecting the unique essence of Chennai's culinary heritage.",
  },
  {
    category: "Seafood Main Course",
    foodName: "Shrimp vindaloo",
    price: 15.0,
    description:
      "A fiery shrimp dish made with onion, tomatoes, potato cubes, and fresh ground spices in Indo-Portuguese style.",
  },
];
export const varietyRiceMainCourse = [
  {
    category: "Variety Rice Main Course",
    foodName: "CURD RICE",
    price: 11.0,
    description:
      "A soothing South Indian comfort food made from cooked rice mixed with yogurt, seasoned with spices, and often tempered with mustard seeds, curry leaves, and garnished with coriander, offering a cooling and refreshing dish.",
  },
  {
    category: "Variety Rice Main Course",
    foodName: "SAMBAR RICE",
    price: 12.0,
    description:
      "A wholesome South Indian dish where cooked rice is served with sambar, a flavorful lentil-based vegetable stew enriched with spices and vegetables, creating a balanced and satisfying meal.",
  },
];
