import React, { useRef , useState }  from "react";
import { Container, Row, Col } from 'react-bootstrap'
import styled from "styled-components";
import axios from "axios";

const Contact = () => {

  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = async (event) => {

    setIsLoading(true);
    event.preventDefault();
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());

    const reqData =  {
      "to": formValues.useremail,
      "name": formValues.username,
      "number": "",
      "company": "",
      "from": "support@datumailabs.com",
      "subject": "Enquiry Email",
      "message": formValues.message
      }

    const result = await axios.post("https://rwbose7loc.execute-api.us-east-1.amazonaws.com/datum-rest-api/cholacafe_sendemail", reqData);
    console.log(result.data)

    setIsLoading(false);
    
    event.target.reset();
    
  };
  return (
    // <section className='section pt-5 top-slant-white2 relative-higher bottom-slant-gray'>
    <section id='contact' className='my-5' >
      <Container fluid >
            <Container fluid align="center">
              <Row className='mb-5 justify-content-center' data-aos='fade'>
                <Col md={7} className='text-center heading-wrap' data-aos='fade-up'>
                  <h2>Contact Us</h2>
                </Col>
              </Row>
              <StyledContactForm align="left">
                <Col className='g-40' lg={40}>
                  <form ref={form} name='contactus' onSubmit={sendEmail}>
                    <Row className='col-md-12'>
                      <Col md={40} className='form-group' >
                        <label>Name</label>
                        <input type="text" name="username" />
                      </Col>
                    </Row>
                    <Row className='col-md-12'>
                      <Col md={40} className='form-group'>
                        <label>Email</label>
                        <input type="email" name="useremail" />
                      </Col>
                    </Row>
                    <Row className='col-md-12' >
                      <Col md={40} className='form-group'>
                        <label>Write Message</label>
                        <textarea name="message" cols={20} rows={8} />
                      </Col>
                    </Row>

                    <Row className="col-md-6">
                      <Col md={40} className='form-group'>
                         <button className='btn btn-primary' type="submit" disabled={isLoading}>
            {isLoading && 'Sending...'}
            {(!isLoading) && 'Submit Message'}
                    </button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </StyledContactForm>
            </Container>

              
      </Container>
    </section>
  )
}

export default Contact

// Styles
const StyledContactForm = styled.div`
  width: 400px;

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 200px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
      justify-content: center;
    }
  }
`;
