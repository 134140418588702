import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import about1 from "../assets/images/Juice.jpg";
import dishes1 from "../assets/images/dishes_0.jpeg";
import dishes3 from "../assets/images/dishes_10.jpeg";
import title from "../assets/images/title.png"

const TheRestaurant = () => {
  return (
    <section id="home" className="mt-5">
      <Container fluid>
        <Row className="mb-5 justify-content-center" data-aos="fade">
          <Col md={7} className="text-center heading-wrap" data-aos="fade-up">
            <h2><img src={title} className="img-fluid about_img_1"  alt="dishes"/></h2>
            <p data-aos="fade-up" data-aos-delay="100">
              Chola Cafe is a vibrant and inviting restaurant that warmly
              welcomes its patrons with a delightful ambiance. This charming
              eatery offers a delectable fusion of traditional Indian flavors
              and modern culinary innovations, creating a unique dining
              experience that tantalizes taste buds and leaves a lasting
              impression on every guest.
            </p>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={4}>
            <img
              src={dishes1}
              alt="dishes"
              className="img-fluid about_img_1"
              data-aos="fade"
              data-aos-delay="200"
            />
          </Col>
          <Col lg={4}>
            <img
              src={about1}
              alt="dishes"
              className="img-fluid about_img_1"
              data-aos="fade"
              data-aos-delay="300"
            />
            
          </Col>
          <Col lg={4}>
            <img
              src={dishes3}
              alt="dishes"
              className="img-fluid about_img_1"
              data-aos="fade"
              data-aos-delay="500"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TheRestaurant;
