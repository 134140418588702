import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Noimage from "../assets/images/Imagenot.png";
import { soup } from "../utils/menu_soup";
import { starterVeg, starterNonVeg } from "../utils/menu_starter";
import {
  clayOven,
  riceNoodles,
  vegMainCourse,
  chickenMainCourse,
  muttonMainCourse,
  seafoodMainCourse,
  varietyRiceMainCourse,
} from "../utils/menu_main";
import { biryaniVeg, biryaniNonVeg } from "../utils/menu_biriyani";
import {
  vegDosa,
  nonVegDosa,
  uthappam,
  southIndianDelicacies,
} from "../utils/menu_dosa";
import { cholabreadCorner } from "../utils/menu_cholaspecial";
import { drinks, desserts } from "../utils/menu_drinkdessert";

const MenuTabs = () => {
  return (
    <section id="menu" className="mt-5">
      <div className="clearfix mb-5 pb-5">
        <Container fluid>
          <Row data-aos="fade">
            <Col md={12} className="text-center heading-wrap">
              <h2>Our Menu</h2>
              <p>Menu some items price may have changed</p>
            </Col>
          </Row>

          <Row className="mb-10 justify-content-center">
            <Col>

              <Tabs
                defaultActiveKey="Dosa"
                transition={false}
                id="noanim-tab-example"
                className="mb-12 justify-content-center"
              >
<Tab
                  eventKey="Dosa"
                  title="Dosa"
                  className="text-center heading-wrap"
                >
                  <Row noGutters>
                    <h1 style={{ paddingTop: "30px" }}>Veg Dosa</h1>
                    {vegDosa.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Non Veg Dosa</h1>
                    {nonVegDosa.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Uthappam</h1>
                    {uthappam.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>
                      south Indian Delicacies
                    </h1>
                    {southIndianDelicacies.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>
              <Tab
                  eventKey="Biryani"
                  title="Biryani"
                  className="text-center heading-wrap"
                >
                  <Row noGutters>
                    <h1 style={{ paddingTop: "30px" }}>Veg Biryani</h1>
                    {biryaniVeg.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Non-Veg Biryani</h1>
                    {biryaniNonVeg.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>

                 <Tab
                  eventKey="Chola’s Special Bread Corner"
                  title="Chola’s Special"
                  className="text-center heading-wrap"
                >
                  <Row noGutters>
                    <h1 style={{ paddingTop: "30px" }}>
                      Chola’s Special Bread Corner
                    </h1>
                    {cholabreadCorner.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>

                 <Tab
                  eventKey="Main course"
                  title="Main Course"
                  className="text-center heading-wrap"
                >
                  <Row noGutters>
                    <h1 style={{ paddingTop: "30px" }}>From the Clay Oven</h1>
                    {clayOven.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage:
                                    dish.image || `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Rice/Noodles</h1>
                    {riceNoodles.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage:
                                    dish.image || `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Veg main course</h1>
                    {vegMainCourse.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage:
                                    dish.image || `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Chicken main course</h1>
                    {chickenMainCourse.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage:
                                    dish.image || `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Mutton main course</h1>
                    {muttonMainCourse.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage:
                                    dish.image || `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Sea Food main course</h1>
                    {seafoodMainCourse.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage:
                                    dish.image || `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Variety Rice</h1>
                    {varietyRiceMainCourse.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage:
                                    dish.image || `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>
                
               <Tab
                  eventKey="Starters"
                  title="Starters"
                  className="text-center heading-wrap"
                >
                  <Row noGutters>
                    <h1 style={{ paddingTop: "30px" }}>Veg Starters</h1>
                    {starterVeg.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1>Non-veg starters</h1>
                    {starterNonVeg.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>

                <Tab
                  eventKey="Soups"
                  title="Soups"
                  className="text-center heading-wrap"
                >
                  <Row noGutters>
                    <h1 style={{ paddingTop: "30px" }}>Soups</h1>
                    {soup.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>
                
               
                <Tab
                  eventKey="Drinks and Desserts"
                  title="Drinks and Desserts"
                  className="text-center heading-wrap"
                >
                  <Row noGutters>
                    <h1 style={{ paddingTop: "30px" }}>Desserts</h1>
                    {drinks.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                    <h1 style={{ paddingTop: "30px" }}>Desserts</h1>
                    {desserts.map((dish, index) => (
                      <Col key={index} md={6} className="g-2">
                        <div>
                          <div className="box_img">
                            <div className="sched d-block d-lg-flex">
                              <div
                                className="bg-image order-2"
                                style={{
                                  backgroundImage: dish.image
                                    ? dish.image
                                    : `url(${Noimage})`,
                                }}
                                data-aos="fade"
                              ></div>
                              <div className="text order-1 d-flex flex-column">
                                <h3>{dish.foodName}</h3>
                                <p>{dish.description}</p>
                                <p className="text-primary h3">${dish.price}</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default MenuTabs;
