
import React, { Fragment } from 'react';
const Privacy = () => {
    return (
<Fragment>

<div className="space-top space-extra-bottom">
    <div className='container'>
        <div className='col-12'>
            <h4>This Privacy Policy is effective as of September 04, 2023</h4>
            <p className='py-2 color-dark'>
            <h5>SECTION 1 – YOUR PERSONAL INFORMATION - </h5> <br></br>
            When you sign up on the ColaCafe app, as part of the buying and selling process, we collect the personal information you give us such as your name, address, phone number, email address and date of birth. We also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system. Emails, and SMS: With your permission, we may send you emails, and SMS messages about our store, new products, order booking status.
            <br></br>
            </p>
            <p className='pb-2 color-dark'>
            <h5>SECTION 2 – CONSENT </h5>
                How do you get my consent? When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only. If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no. How do I withdraw my consent? If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting us at support@datumailabs.com
            </p>
            <p className='pb-2 color-dark'>
            <h5> SECTION 3 – DISCLOSURE </h5>
            We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
            </p>
            <p className='pb-2 color-dark'>
                <h5>SECTION 4- INFORMATION WE COLLECT</h5>
                We may collect a variety of personal information from or about you or your devices from various sources, as described below.
                <h6>A. Information You Provide to Us Placing an Order. </h6>
                <p className='py-2 color-dark'>
                    <ol start='1'>
                        <li> We collect the personal information you provide to us when you use our Services.  For example, when you place an order with a restaurant through our Services, we will collect your personal information, including your name, phone number, email address, address, date of birth, Payment Information (as defined below), and any other information you provide, including details of your order and delivery instructions.  If you are a member of a restaurant’s loyalty program, we will collect the personal information you provide to us in connection with your membership.  If you leave feedback for the restaurant or driver, we will collect the personal information you include in your feedback.</li>
                        <li> Merchants.  If you are a merchant, or an employee of a merchant, we will collect your personal information in connection with your application to and use of the Services.  For example, when you use or sign up for our Services, we will collect your name, email address, mailing address, phone number, restaurant name and contact information, etc..  In addition to your contact information, we will collect your tax identification number, national identification number (e.g., Social Security number), and your banking and payment card information.
Drivers.  If you sign up to use our Services as a driver, we will collect your personal information, including your name, email address, phone number.  When you use our Services to pick up and deliver an order, we will collect your personal information. </li>
<li>Making a Payment.  When you place an order with a restaurant through our Services, your credit card information, billing information, and any other financial information necessary to complete your purchase (“Payment Information”) is processed by our third-party payment processor, and we do not collect, store, or process your Payment Information.  For more information, please see the “Payment Processing” section below.</li>
‍<li>Communications.  If you contact us directly, we may receive additional personal information about you.  For example if you represent a restaurant and contact us for a demo, we will receive your name, phone number, and email address.  If you subscribe to our marketing communications, we will receive your name, email address, phone number, and order history.</li>
‍<li>Careers.  If you apply for a job with us, you may submit your contact information and your resume online.  We will collect your contact information and the personal information you choose to provide on your resume, such as your education and employment experience.  You may also apply through certain third-party websites.  If you do so, we will collect the personal information you make available to us through those websites in connection with your job application.</li>
                    </ol>
                </p>

                <h6> B. Information We Collect When You Use Our Services </h6>
                <p className='py-2 color-dark'>
                    <ol start='1'>
                        <li>Device Information.  We receive information about the device and software you use to access our Services, including internet protocol (IP) address, web browser type, and operating system version. </li>
                        <li>Usage Information.  To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with them, such as the length of time you spend on a page, objects such as hyperlinks you click on, any point at which you terminate an order, and the dates and times of your visits. </li>
                        <li>Location Information.  When you use our Services, if you allow us, we will receive your precise location information.  We may also collect the precise location of your device when our Services are running in the foreground or background.  We use your location information to insert a description of how you use location information.  We may also infer your general location information, for example by using your internet protocol (IP) address. </li>
                        <li>Information from Cookies and Similar Technologies.  We and our third-party partners may collect information using cookies, pixel tags, or similar technologies.  Our third-party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services.  Cookies are small text files containing a string of alphanumeric characters.  We may use both session cookies and persistent cookies.  A session cookie disappears after you close your browser.  A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Services.
                        Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings.  Please note that if you delete or choose not to accept cookies from the Services, you may not be able to utilize the features of the Services to their fullest potential.</li>
                    </ol>
                </p>

                <h6>C. Personal Information We Receive from Third Parties </h6>
                <p className='py-2 color-dark'>
                    <ol start='1'>
                        <li> Restaurants-  If you have placed an order with a restaurant through our Services, we will receive information regarding your order history from the restaurant.</li>
‍                         <li> Partners- We may receive personal information about you from third parties such as data or marketing partners and combine it with other personal information we have about you.</li>
                    </ol>
                </p>
                <h6>D. PAYMENT </h6>
                <p className='py-2 color-dark'>
                        Our Services uses Square Pay for processing payments. We/Square Pay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved. Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council.
                        <br></br> 
                        When you make a payment to a restaurant through our Services, your payment is processed by a third-party payment processor such as Square (“Payment Processor”).  This Payment Processor may collect personal information from you, including your name, email address, billing address, and Payment Information in connection with your order.  The use of your personal information by the Payment Processor is governed by its terms and conditions and privacy policy, and personal information we receive about you from our Payment Processor is governed by this Privacy Policy.
                        <br></br>
                        PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers. For more insight, you may also want to read terms and conditions of Square Pay on https://squareup.com/us/en/legal/general/privacy
                </p>

            <p className='pb-2 color-dark'>
                <h5>SECTION 5 - YOUR CHOICES </h5>
                <p className='py-2 color-dark'>
                    <ol start='1'>
                        <li>Location Information.  You can prevent your device from sharing precise location information at any time through your device’s operating system settings.  However, location is core to our Services and without it, we may not be able to provide all the functionality our Services.</li>
‍
                        <li>Marketing Communications.  You can unsubscribe from our promotional emails via the link provided in the emails.  Even if you opt-out of receiving promotional messages from us, you will continue to receive administrative messages from us.</li>
‍
                        <li> Do Not Track.  There is no accepted standard on how to respond to Do Not Track signals, and we do not respond to such signals.</li>
                    </ol>
                </p>
            </p>   
            <p className='pb-2 color-dark'>
                <h5>SECTION 6 - THIRD-PARTY SERVICES</h5>
                In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions. For these providers, we recommend that you read their privacy policies so you can understand the manner in which these providers will handle your personal information. In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So, if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. Once you leave our store’s website or are redirected to a third-party website or application, this Privacy Policy or our website’s Terms of Service no longer govern you. Links: When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.
                <br></br>
​                 As you access and use our services, we collect certain information from you, including but not limited to, phone number, email address, device make-details, and IP address. By accessing and using our services, you expressly consent to the sharing and disclosure of your information so collected, with our third-party service providers, business partners, and agents. For example, your information may be shared with third-party services like GetSimpl to ensure fraud prevention and PayLater check out experience. Please refer to the third-party privacy policy for more details.
                <br></br>
                Our Services may contain links to other websites, products, or services that we do not own or operate.  We are not responsible for the privacy and advertising practices of these third parties.  Please be aware that this Privacy Policy does not apply to your activities on these third-party services or any personal information you disclose to these third parties.  We encourage you to read their privacy policies before providing any personal information to them.

            </p>   
            <p className='pb-2 color-dark'>
                <h5>SECTION 7 – SECURITY </h5>
                Our services are reasonable efforts to protect your personal information by using physical and electronic safeguards designed to improve the security of the personal information we maintain.  However, as no electronic transmission or storage of personal information can be entirely secure, we can make no guarantees as to the security or privacy of your personal information.                
            </p>   
            <p className='pb-2 color-dark'>
                <h5>SECTION 8 –  AGE OF CONSENT </h5>
                By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
            </p>   
            <p className='pb-2 color-dark'>
                <h5>SECTION 9 – CHANGES TO THIS PRIVACY POLICY  </h5>
                Our Services reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. If the store is acquired or is merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
            </p>  
            <p className='pb-2 color-dark'>
                <h5>SECTION 10 – CHILDREN'S PRIVACY  </h5>
                Our Services do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no parts of our Services are directed to children.  If you learn that a child has provided us with personal information in violation of this Privacy Policy, then you may alert us at creators@datumailabs.com
            </p>  
            <p className='pb-2 color-dark'>
                <h5>SECTION 11 – INTERNATIONAL VISITORS  </h5>
                Our Services are hosted in the United States and intended for visitors located within the United States.  If you choose to use our Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing.  Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Services.  By providing any information, including personal information, on or to our Services, you consent to such transfer, storage, and processing.
            </p>  
            <p className='pb-2 color-dark'>
                <h5>SECTION 12 – CONTACT INFORMATION  </h5>
                DatumAI Labs is responsible for processing your information.  If you have any questions, comments, or concerns about our processing activities, please email us at creators@datumailabs.com or write feedback in the link below.
                <br></br>
                <a href="https://www.datumailabs.com/contact">www.datumailabs.com</a>
            </p>  

            </p>
        </div>

    </div>
</div>
</Fragment>

);
};

export default Privacy;