import tomatosoup from "../assets/menuimages/soups/tomatosoup.jpg";
import chickenpeppersoup from "../assets/menuimages/soups/chickenpeppersoup.jpg";
import mushroomsoup from "../assets/menuimages/soups/mushroomsoup.jpg";

export const soup = [
  {
    category: "Soups",
    foodName: "Tomato Soup",
    price: 5.0,
    description:
      "Aromatic spices infused in a rich tomato base, creating a warm and flavorful delight.",
    image: `url(${tomatosoup})`,
  },
  {
    category: "Soups",
    foodName: "Mutton Bone Soup",
    price: 5.0,
    description:
      "Aromatic blend of tender mutton bones slow cooked in traditional Indian spices, offering a delightful and flavorful soup experience.",
  },
  {
    category: "Soups",
    foodName: "CHICKEN PEPPER SOUP",
    price: 5.0,
    description:
      "Tender chicken infused with aromatic Indian spices and a hint of pepper, delivering a zesty and satisfying soup.",
    image: `url(${chickenpeppersoup})`,
  },
  {
    category: "Soups",
    foodName: "MUSHROOM SOUP",
    price: 5.0,
    description:
      "Succulent mushrooms infused with aromatic Indian spices, creating a delicious clear soup.",
    image: `url(${mushroomsoup})`,
  },
];
