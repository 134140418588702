import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home.js';
import Privacy from './components/Privacy.js';
import GoToAppStore from './components/GotoAppStore.js';


const App = () => (
  <Router>
    <Routes>
      {/* Route for Home page */}
      <Route path="/" element={<Home/>} />
      {/* Route for Privacy Policy page */}
      <Route path="/privacy" element={<Privacy/>} />
      {/* Route for QR App Store page */}
      <Route path="/GoToAppStore" element={<GoToAppStore/>} />
          </Routes>
  </Router>
);

export default App;
