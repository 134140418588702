import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/fonts/ionicons/css/ionicons.min.css'
import './assets/fonts/fontawesome/css/font-awesome.min.css'
import './assets/fonts/flaticon/font/flaticon.css'
import './index.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
