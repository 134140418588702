import vegetabledumbiryani from "../assets/menuimages/biriyani/vegetabledumbiriyani.jpg";
import paneerbiryani from "../assets/menuimages/biriyani/paneerbiriyani.jpg";
import nawabichickenbiryani from "../assets/menuimages/biriyani/nawabichickenbiriyani.jpg";
import supremebonelesschickenbiryani from "../assets/menuimages/biriyani/supremebonelesschickenbiriyani.jpg";
import nellorebonelesschickenbiryani from "../assets/menuimages/biriyani/nellorebonelesschickenbiriyani.jpg";
import dindugalthalapakattumuttondumbiryani from "../assets/menuimages/biriyani/muttonbiriyani.jpeg";
import fishbiryani from "../assets/menuimages/biriyani/fishbiriyani.jpg";
// import shrimpbiryani from "../assets/menuimages/biriyani/shrimpbiryani.jpg";
import eggbiryani from "../assets/menuimages/biriyani/eggbiriyani.jpg";

export const biryaniVeg = [
  {
    category: "Biryani Veg",
    foodName: "VEGETABLE DUM BIRYANI",
    price: 13.0,
    description:
      "A fragrant and flavorful Indian rice dish cooked in layers, featuring a blend of aromatic basmati rice, assorted vegetables, and a medley of spices, slow-cooked to perfection for a harmonious and delightful vegetarian feast.",
    image: `url(${vegetabledumbiryani})`,
  },
  {
    category: "Biryani Veg",
    foodName: "PANEER BIRYANI",
    price: 14.0,
    description:
      "A delectable Indian rice dish where succulent cubes of paneer (Indian cottage cheese) are layered with fragrant basmati rice, aromatic spices, and herbs, resulting in a flavorful and satisfying vegetarian biryani.",
    image: `url(${paneerbiryani})`,
  },
];

export const biryaniNonVeg = [
  {
    category: "Biryani Non-Veg",
    foodName: "NAWABI CHICKEN BIRYANI",
    price: 15.0,
    description:
      "A regal and aromatic Indian rice delicacy, featuring tender chicken marinated in rich spices, layered with fragrant basmati rice, and slow-cooked to perfection in the traditional ‘Dum’ style, capturing the opulent flavors of Nawabi cuisine.",
    image: `url(${nawabichickenbiryani})`,
  },
  {
    category: "Biryani Non-Veg",
    foodName: "SUPREME BONELESS CHICKEN BIRYANI",
    price: 15.0,
    description:
      "An exquisite and indulgent Indian rice dish, showcasing succulent pieces of boneless chicken marinated with a blend of spices, layered with fragrant rice, and cooked to perfection, resulting in a luxurious and flavorful biryani experience.",
    image: `url(${supremebonelesschickenbiryani})`,
  },
  {
    category: "Biryani Non-Veg",
    foodName: "NELLORE BONELESS CHICKEN BIRYANI",
    price: 16.0,
    description:
      "A flavorful and aromatic South Indian rice dish featuring tender boneless chicken, marinated with traditional spices, and layered with fragrant rice capturing the distinctive taste of Nellore's culinary heritage.",
    image: `url(${nellorebonelesschickenbiryani})`,
  },
  {
    category: "Biryani Non-Veg",
    foodName: "DINDUGAL THALAPAKATTUbiriyaniN DUM BIRYANI",
    price: 17.0,
    description:
      "A renowned South Indian specialty where succulent mutton is marinated in authentic spices, layered with fragrant seeraga samba rice, and slow-cooked to perfection, embodying the rich and robust flavors of Dindugal's famous Thalapakattu style.",
    image: `url(${dindugalthalapakattumuttondumbiryani})`,
  },
  {
    category: "Biryani Non-Veg",
    foodName: "FISH BIRYANI",
    price: 17.0,
    description:
      "A delectable Indian rice dish featuring marinated fish pieces layered with aromatic basmati rice, fragrant spices, and herbs, creating a delightful fusion of seafood and biryani flavors.",
    image: `url(${fishbiryani})`,
  },
  {
    category: "Biryani Non-Veg",
    foodName: "SHRIMP BIRYANI",
    price: 17.0,
    description:
      "A flavorful Indian rice dish showcasing succulent shrimp marinated with aromatic spices, layered with fragrant basmati rice, and slow-cooked to perfection, resulting in a delightful fusion of seafood and biryani goodness.",
    // image: `url(${shrimpbiryani})`,
  },
  {
    category: "Biryani Non-Veg",
    foodName: "EGG BIRYANI",
    price: 14.0,
    description:
      "A delightful Indian rice dish featuring three boiled eggs infused with aromatic spices, layered with fragrant basmati rice, and cooked to perfection, offering a unique and satisfying twist on traditional biryani flavors.",
    image: `url(${eggbiryani})`,
  },
];
