import specialmadrascoffee from "../assets/menuimages/drinksndeserts/madrascoffee.jpg";
import specialtea from "../assets/menuimages/drinksndeserts/specialtea.jpg";
import mangolassi from "../assets/menuimages/drinksndeserts/mangolassi.jpg";
import fountainsoda from "../assets/menuimages/drinksndeserts/fountainsoda.jpg";
import bottledwater from "../assets/menuimages/drinksndeserts/bottledwater.png";
import gulabjamun from "../assets/menuimages/drinksndeserts/gulabjamun.jpg";
import rasmalai from "../assets/menuimages/drinksndeserts/rasamalai.jpg";
import carrothalwa from "../assets/menuimages/drinksndeserts/carrothalwa.jpg";

export const drinks = [
  {
    category: "Drinks",
    foodName: "Special madras coffee",
    price: 3.0,
    description:
      "A strong and aromatic South Indian coffee prepared using dark roasted coffee beans and chicory, creating a unique and rich flavor.",
    image: `url(${specialmadrascoffee})`,
  },
  {
    category: "Drinks",
    foodName: "Special tea",
    price: 3.0,
    description:
      "A spiced and aromatic Indian tea made by brewing black tea with a blend of spices like cinnamon, cardamom, cloves, and ginger, often infused with milk and sweetened with sugar for a flavorful and comforting beverage.",
    image: `url(${specialtea})`,
  },
  {
    category: "Drinks",
    foodName: "Mango lassi",
    price: 4.0,
    description:
      "Creamy and refreshing Indian drink made with yogurt and ripe mangoes, often sweetened and flavored with cardamom.",
    image: `url(${mangolassi})`,
  },
  {
    category: "Drinks",
    foodName: "Fountain soda",
    price: 3.0,
    description: "Pepsi, Diet Pepsi, Mountain Dew, Sierra Mist, Dr. Pepper",
    image: `url(${fountainsoda})`,
  },
  {
    category: "Drinks",
    foodName: "Bottled water",
    price: 1.0,
    description: "",
    image: `url(${bottledwater})`,
  },
];

export const desserts = [
  {
    category: "Desserts",
    foodName: "Gulab jamun",
    price: 5.0,
    description:
      "Delicate, deep-fried milk-based dessert balls soaked in sugar syrup, a popular treat in South Asian cuisine.",
    image: `url(${gulabjamun})`,
  },
  {
    category: "Desserts",
    foodName: "Rasmalai",
    price: 5.0,
    description:
      "Sweet Indian dessert made from soft cheese patties soaked in saffron-infused milk, flavored with cardamom.",
    image: `url(${rasmalai})`,
  },
  {
    category: "Desserts",
    foodName: "Carrot halwa",
    price: 5.0,
    description:
      "Traditional Indian dessert made by slow-cooking grated carrots in milk, sugar, and ghee until rich and flavorful.",
    image: `url(${carrothalwa})`,
  },
];
