import samosa from "../assets/menuimages/starters/samosa.jpg";
import mysorebonda from "../assets/menuimages/starters/mysorebonda.jpg";
import bajji from "../assets/menuimages/starters/bajji.jpg";
import meduvada from "../assets/menuimages/starters/meduvada.jpg";
import onionpakoda from "../assets/menuimages/starters/onionpakoda.jpg";
import aloobonda from "../assets/menuimages/starters/aloobonda.jpg";
import sambarvada from "../assets/menuimages/starters/sambarvada.jpg";
import gobi65 from "../assets/menuimages/starters/gobi65.jpg";
import paneer65 from "../assets/menuimages/starters/paneer65.jpg";
import chillipaneer from "../assets/menuimages/starters/chillipaneer.jpg";
import gobimanchurian from "../assets/menuimages/starters/gobimanchurian.jpg";
import mushroom65 from "../assets/menuimages/starters/mushroom65.jpg";
import chillimushroom from "../assets/menuimages/starters/chillimushroom.jpg";

//Non - veg starters

import chicken65 from "../assets/menuimages/starters/chicken65.jpg";
import chickenlollipop from "../assets/menuimages/starters/chickenlollipop.jpg";
import chickenmasalalollipop from "../assets/menuimages/starters/chickenmasalalollipop.jpg";
import chickensukka from "../assets/menuimages/starters/chickensukka.jpg";
// import chicken555 from "../assets/menuimages/starters/chicken555.jpg";
import chickenmanchurian from "../assets/menuimages/starters/chickenmanchurian.jpeg";
// import chennaichillichicken from "../assets/menuimages/starters/chennaichillichicken.jpg";
// import andhrachillichicken from "../assets/menuimages/starters/andhrachillichicken.jpg";
// import kozhichickenvepudu from "../assets/menuimages/starters/kozhichickenvepudu.jpg";
// import pepperchickensukka from "../assets/menuimages/starters/pepperchickensukka.jpg";
import muttonsukka from "../assets/menuimages/starters/muttonsukka.jpeg";
// import muttonvaruval from "../assets/menuimages/starters/cholasspecialmuttonvaruval.jpg";
// import prawnsukka from "../assets/menuimages/starters/prawnsukka.jpg";
// import fishapollo from "../assets/menuimages/starters/fishapollo.jpg";
import fishtawaroast from "../assets/menuimages/starters/fishtawaroast.jpg";
import fish65 from "../assets/menuimages/starters/fish65.jpg";
import omelette from "../assets/menuimages/starters/omelette.jpg";
// import eggpodimas from "../assets/menuimages/starters/eggpodimas.jpg";
import eggpepperroast from "../assets/menuimages/starters/eggpepperroast.jpg";

export const starterVeg = [
  {
    category: "Starters (Vegetarian)",
    foodName: "SAMOSA (2PCS)",
    price: 7.0,
    description:
      "Crispy pastry filled with spiced potatoes and peas, a delightful blend of flavors and textures.",
    image: `url(${samosa})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "MYSORE BONDA (5PCS)",
    price: 7.0,
    description:
      "Deep-fried fritters made from fermented lentil and rice batter, crispy outside, soft and fluffy inside, a delectable South Indian snack.",
    image: `url(${mysorebonda})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Assorted bajji (6PCS)",
    price: 7.0,
    description:
      "Mixed fritters, variety of veggies, dipped in spiced chickpea batter, deep-fried, delightful snacks.",
    image: `url(${bajji})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "MEDU VADA (2pcs)",
    price: 7.0,
    description:
      "Deep-fried, savory, and crispy lentil-based fritter made from a mixture of urad dal (black gram), spices like cumin and black pepper, and fresh herbs, usually served with chutney or sambar.",
    image: `url(${meduvada})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Onion pakoda",
    price: 7.0,
    description:
      "Thinly sliced onions, mixed with gram flour, deep-fried, crispy, flavorful Indian tea-time snack.",
    image: `url(${onionpakoda})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Aloo bonda (5 pcs)",
    price: 7.0,
    description:
      "Spiced potato filling, coated in chickpea flour batter, deep-fried, popular South Indian snack.",
    image: `url(${aloobonda})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "SAMBAR VADA",
    price: 8.0,
    description:
      "A combination of medu vada (deep-fried lentil fritters) soaked in flavorful and tangy sambar (spiced lentil stew) garnished with fresh coriander, served with chutney.",
    image: `url(${sambarvada})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "GOBI 65",
    price: 13.0,
    description:
      "Spicy and crispy deep-fried cauliflower appetizer, marinated in a blend of Indian spices, ginger-garlic paste, and cornflower making it irresistibly flavorful and popular vegetarian dish served as a tantalizing starter.",
    image: `url(${gobi65})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "PANEER 65",
    price: 13.0,
    description:
      "A delightful and spicy vegetarian appetizer, featuring bite-sized paneer cubes marinated in a mixture of Indian spices, yogurt, and cornflower, deep-fried to perfection, creating a crispy exterior and a soft, flavorful center.",
    image: `url(${paneer65})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "CHILLI PANEER",
    price: 13.0,
    description:
      "Indo-Chinese fusion dish, consisting of crispy paneer cubes sautéed with colorful bell peppers, onions, and a flavorful blend of sauces creating a sweet and spicy glazed coating with a tantalizing balance of flavors.",
    image: `url(${chillipaneer})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "GOBI MANCHURIAN/CHILLI GOBI",
    price: 13.0,
    description:
      "A spicy and flavorful vegetarian dish featuring crispy cauliflower florets coated in a tangy, savory, and aromatic chili-garlic sauce.",
    image: `url(${gobimanchurian})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "MUSHROOM 65",
    price: 13.0,
    description:
      "Button mushrooms are marinated in a spicy, tangy yogurt-based mixture, coated with a crispy masala, and then deep-fried.",
    image: `url(${mushroom65})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "CHILLI MUSHROOM",
    price: 13.0,
    description:
      "Indo-Chinese fusion consisting of succulent mushroom pieces sautéed with bell peppers, onions, and a flavorful blend of spices.",
    image: `url(${chillimushroom})`,
  },
];
export const starterNonVeg = [
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "CHICKEN 65",
    price: 13.0,
    description:
      "Chickendish made with marinated boneless chicken pieces, a blend of aromatic spices, curry leaves, and yogurt, offering a flavorful, tangy, and crispy appetizer.",
    image: `url(${chicken65})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "CHICKEN LOLLIPOP",
    price: 13.0,
    description:
      "Chickendrumettes are frenched, marinated in a spiced mixture, deep-fried until golden and crispy, and served with a tangy and flavorful dip, resembling a lollipop shape.",
    image: `url(${chickenlollipop})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken masala lollipop",
    price: 14.0,
    description:
      "Succulent drumettes, marinated, spicy masala coating, fried until crispy.",
    image: `url(${chickenmasalalollipop})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "CHOLA’S CHICKEN SUKKA ( BONE-IN / BONELESS )",
    price: 14.0,
    description:
      "A dry chicken dish, prepared with tender bone-in or boneless chicken pieces, sautéed with a blend of aromatic spices, grated coconut, and onions.",
    image: `url(${chickensukka})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken 555",
    price: 14.0,
    description:
      "Indo-Chinese fusion, crispy fried chicken, aromatic spices, tangy and slightly sweet glaze. Deliciously addictive.",
    // image: `url(${chicken555})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken Manchurian",
    price: 14.0,
    description:
      "Indo-Chinese delight, fried chicken balls, tangy sauce, bell peppers, garlic-ginger, a harmonious fusion.",
    image: `url(${chickenmanchurian})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "CHENNAI CHILLI CHICKEN",
    price: 14.0,
    description:
      "Indo-Chinese chicken dish, featuring tender chicken pieces marinated in a tangy sauce, stir-fried with onions, bell peppers, and green chilies.",
    // image: `url(${chennaichillichicken})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Andhra chilli chicken",
    price: 14.0,
    description:
      "Spicy, flavorful chicken dish, vibrant red color, fiery taste, popular Indian appetizer.",
    // image: `url(${andhrachillichicken})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Kozhi (chicken) vepudu",
    price: 14.0,
    description:
      "Popular south Indian Andhra-style chicken fry, marinated, spices, pan-fried, crispy, delicious appetizer.",
    // image: `url(${kozhichickenvepudu})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Pepper chicken sukka",
    price: 14.0,
    description:
      "Roasted chicken, bold pepper flavors, South Indian spices, dry preparation, delectable side dish.",
    // image: `url(${pepperchickensukka})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "MUTTON SUKKA",
    price: 17.0,
    description:
      "Mutton pieces with a blend of aromatic spices and onions fried until the meat is tender and coated in a rich spicy masala.",
    image: `url(${muttonsukka})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "CHOLA’S SPECIAL MUTTON VARUVAL",
    price: 17.0,
    description:
      "Tender mutton pieces are marinated in a flavorful spice blend, pan-fried until caramelized and aromatic, resulting in a succulent and spicy dry mutton preparation.",
    // image: `url(${muttonvaruval})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "PRAWN SUKKA",
    price: 16.0,
    description:
      "Succulent prawns are cooked in a blend of aromatic spices, coconut, and tamarind, creating a flavorful and dry dish with a delightful balance of tanginess and spiciness.",
    // image: `url(${prawnsukka})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Fish Apollo",
    price: 14.0,
    description:
      "Crispy fish bites, tangy-spicy sauce, Indo-Chinese fusion, appetizing and flavorful.",
    // image: `url(${fishapollo})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "FISH TAWA ROAST",
    price: 14.0,
    description:
      "Marinated fish filets are pan-roasted on a flat griddle (tawa) with a blend of spices, onions, and tomatoes, resulting in a flavorful and charred crust.",
    image: `url(${fishtawaroast})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "FISH 65",
    price: 14.0,
    description:
      "Bite-sized fish pieces are marinated in a tangy, aromatic spice mixture, deep-fried until crispy, and tossed with curry leaves and green chilies.",
    image: `url(${fish65})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "OMELETTE",
    price: 5.0,
    description:
      "Egg dish made with beaten eggs, finely chopped onions, tomatoes, green chilies, and spices, pan-fried.",
    image: `url(${omelette})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "EGG PODIMAS",
    price: 5.0,
    description:
      "Scrambled egg dish cooked with onions, tomatoes, green chilies, and spices.",
    // image: `url(${eggpodimas})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "EGG PEPPER ROAST",
    price: 5.0,
    description:
      "Boiled eggs are cooked with a peppery masala made of onions, tomatoes, ginger, garlic, and a blend of aromatic spices.",
    image: `url(${eggpepperroast})`,
  },
];
