import chapati from "../assets/menuimages/cholaspecial/chapati.jpg";
import poori from "../assets/menuimages/cholaspecial/poori.JPG";
import cholapuri from "../assets/menuimages/cholaspecial/bhatura.jpg";
import parotta from "../assets/menuimages/cholaspecial/parotta.jpg";
import veechuparotta from "../assets/menuimages/cholaspecial/veechuparotta.jpg";
import eggveechuparotta from "../assets/menuimages/cholaspecial/eggveechuparotta.jpg";
import kothuparotta from "../assets/menuimages/cholaspecial/kothuparotta.jpg";
import chilliparottaveg from "../assets/menuimages/cholaspecial/chilliparotta.jpg";
import tandooriroti from "../assets/menuimages/cholaspecial/tandooriroti.jpg";
import methinaan from "../assets/menuimages/cholaspecial/methinaan.jpg";
import mintnaan from "../assets/menuimages/cholaspecial/mintnaan.jpg";
import greenchillinaan from "../assets/menuimages/cholaspecial/greenchillinaan.jpg";
import butternaan from "../assets/menuimages/cholaspecial/butternaan.jpg";
import garlicnaan from "../assets/menuimages/cholaspecial/garlicnaan.jpg";
import bhatura from "../assets/menuimages/cholaspecial/bhatura.jpg";
import poori1pc from "../assets/menuimages/cholaspecial/poori.JPG";
import parotta1pc from "../assets/menuimages/cholaspecial/parotta.jpg";
import kulcha from "../assets/menuimages/cholaspecial/kulcha.jpg";

export const cholabreadCorner = [
  {
    category: "Chola’s Special Bread Corner",
    foodName: "CHAPATI WITH VEG OR CHICKEN",
    price: 11.0,
    description:
      "A versatile Indian wheat bread, complements well with a choice of flavorful vegetable or tender chicken curry, creating a satisfying meal.",
    image: `url(${chapati})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Poori with potato masala or channa",
    price: 11.0,
    description:
      "Deep-fried bread (poori) served with spiced potato masala or chickpea curry, a flavorful Indian dish.",
    image: `url(${poori})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Chola puri (bhatura)",
    price: 12.0,
    description:
      "Deep-fried bread (bhatura) served with spicy chickpea curry (chola), a popular North Indian dish.",
    image: `url(${cholapuri})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "parotta - with veg or chicken kurma",
    price: 12.0,
    description:
      "Flaky, layered South Indian flatbread served with a choice of aromatic vegetable or tender chicken curry.",
    image: `url(${parotta})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Veechu parotta- with veg or chicken kurma",
    price: 12.0,
    description:
      "Flaky, layered soft and fluffy flatbread served with a choice of aromatic vegetable or tender chicken curry.",
    image: `url(${veechuparotta})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Egg Veechu parotta- with veg or chicken kurma",
    price: 12.0,
    description:
      "Flaky, layered soft and fluffy flatbread served with a choice of aromatic vegetable or tender chicken curry.",
    image: `url(${eggveechuparotta})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Kothu parotta (VEG)",
    price: 12.0,
    description:
      "Shredded parotta, mixed with eggs or vegetables, spices aromatic dish, popular in South India.",
    image: `url(${kothuparotta})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Kothu parotta (EGG)",
    price: 13.0,
    description:
      "Shredded parotta, mixed with eggs or vegetables, spices aromatic dish, popular in South India.",
    image: `url(${kothuparotta})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Kothu parotta (CHICKEN)",
    price: 13.0,
    description:
      "Shredded parotta, mixed with chicken or vegetables, spices aromatic dish, popular in South India.",
    image: `url(${kothuparotta})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Kothu parotta (MUTTON)",
    price: 14.0,
    description:
      "Shredded parotta, mixed with mutton or vegetables, spices aromatic dish, popular in South India.",
    image: `url(${kothuparotta})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Chilli parotta (VEG)",
    price: 13.0,
    description:
      "Shredded parotta, tossed with spicy sauce, onions, bell peppers, Indo-Chinese fusion, flavorful street food.",
    image: `url(${chilliparottaveg})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Tandoori roti",
    price: 4.0,
    description:
      "Indian flatbread, cooked in a tandoor/clay oven, with a slightly smoky flavor and texture.",
    image: `url(${tandooriroti})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Methi naan",
    price: 4.0,
    description:
      "Flavored Indian bread made with fenugreek leaves, often cooked in a tandoor for a unique taste.",
    image: `url(${methinaan})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Mint naan",
    price: 4.0,
    description:
      "Indian flatbread infused with fresh mint, offering a delightful aromatic twist, often cooked in a tandoor.",
    image: `url(${mintnaan})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Green chilli naan",
    price: 4.0,
    description:
      "Indian flatbread infused with chopped green chilies, adding a spicy kick, often cooked in a tandoor.",
    image: `url(${greenchillinaan})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Butter naan",
    price: 3.0,
    description:
      "Soft Indian bread brushed with butter, a popular accompaniment to curries and dishes.",
    image: `url(${butternaan})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Garlic naan",
    price: 4.0,
    description:
      "Flavored Indian bread topped with minced garlic, often cooked in a tandoor.",
    image: `url(${garlicnaan})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Bhatura",
    price: 5.0,
    description:
      "Deep-fried Indian bread made from fermented dough, puffy and soft.",
    image: `url(${bhatura})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Poori (1pc)",
    price: 4.0,
    description: "Deep-fried Indian bread (poori).",
    image: `url(${poori1pc})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Parotta (1pc)",
    price: 4.0,
    description: "Flaky, layered South Indian flatbread (parotta).",
    image: `url(${parotta1pc})`,
  },
  {
    category: "Chola’s Special Bread Corner",
    foodName: "Kulcha",
    price: 4.0,
    description:
      "Soft and fluffy Indian bread, often leavened with yogurt and cooked in a tandoor, served with various accompaniments.",
    image: `url(${kulcha})`,
  },
];
