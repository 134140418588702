import React from "react";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import logo from "../assets/images/CholaCafeLogo.png";

const Header = () => {
  return (
    <header role="banner">
      <Navbar expand="md" bg="dark" variant="dark">
        <Container fluid>
          <Navbar.Brand href="">CHOLA CAFE<Image src={logo} alt="Chola Cafe" className="d-inline-block align-top" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarsExample05" />

          <Navbar.Collapse id="navbarsExample05">
            <Nav className="ml-auto pl-lg-5 pl-0">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#menu">Menu</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
