import plaindosa from "../assets/menuimages/dosa/plaindosa.jpg";
import masaladosa from "../assets/menuimages/dosa/masaladosa.jpg";
import mysoredosa from "../assets/menuimages/dosa/mysoredosa.jpg";
import gheedosa from "../assets/menuimages/dosa/gheedosa.jpg";
import ravadosa from "../assets/menuimages/dosa/ravadosa.jpg";
// import mallidosa from "../assets/menuimages/dosa/mallidosa.jpg";
import kaldosa from "../assets/menuimages/dosa/kaldosa.jpeg";
import eggdosa from "../assets/menuimages/dosa/eggdosa.jpg";
import chickendosa from "../assets/menuimages/dosa/chickendosa.jpg";
import muttondosa from "../assets/menuimages/dosa/muttondosa.jpeg";
import plainutthapam from "../assets/menuimages/dosa/plainutthapam.jpg";
import onionutthapam from "../assets/menuimages/dosa/onionutthapam.jpg";
import vegutthapam from "../assets/menuimages/dosa/vegutthapam.jpg";
import idly from "../assets/menuimages/dosa/idly.jpg";
import sambaridly from "../assets/menuimages/dosa/sambaridly.jpeg";
import minigheeidly from "../assets/menuimages/dosa/minigheeidly.JPG";
import chennaipodiidly from "../assets/menuimages/dosa/chennaipodiidly.jpg";
import gheepongal from "../assets/menuimages/dosa/gheepongal.jpg";
import idlyvadacombo from "../assets/menuimages/dosa/idlyvadacombo.jpeg";
import pongalvadacombo from "../assets/menuimages/dosa/pongalvadacombo.jpg";

export const vegDosa = [
  {
    category: "Veg - Dosa",
    foodName: "PLAIN DOSA",
    price: 11.0,
    description:
      "Thin, crispy South Indian crepe made from fermented rice batter.",
    image: `url(${plaindosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Masala dosa",
    price: 12.0,
    description:
      "Crunchy dosa filled with spiced potato, a South Indian culinary delight.",
    image: `url(${masaladosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "MYSORE DOSA",
    price: 12.0,
    description:
      "Crispy South Indian crepes smeared with spicy Mysorian red chutney, flavorsome.",
    image: `url(${mysoredosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "GHEE DOSA",
    price: 12.0,
    description:
      "A golden and crisp South Indian crepe made with fermented batter, cooked in clarified butter (ghee), offering a rich and indulgent flavor.",
    image: `url(${gheedosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "RAVA DOSA",
    price: 12.0,
    description:
      "A lacy and crispy South Indian crepe made with semolina (rava), rice flour, and spices, known for its unique texture and quick preparation.",
    image: `url(${ravadosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "MALLI DOSA",
    price: 12.0,
    description:
      "A flavorful South Indian crepe topped with fragrant coriander (malli) chutney, enhancing the taste of the dosa.",
    // image: `url(${mallidosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "KAL DOSA",
    price: 12.0,
    description:
      "Fluffy, fermented South Indian crepe, smaller and thicker, often served in sets with flavorful chutneys.",
    image: `url(${kaldosa})`,
  },
];

export const nonVegDosa = [
  {
    category: "Non-Veg Dosa",
    foodName: "EGG DOSA",
    price: 12.0,
    description:
      "Egg-filled South Indian crepe, a delicious fusion of dosa and scrambled eggs.",
    image: `url(${eggdosa})`,
  },
  {
    category: "Non-Veg Dosa",
    foodName: "CHICKEN DOSA",
    price: 13.0,
    description:
      "Savory crepe filled with spiced chicken, a delightful fusion of South Indian dosa and flavorful chicken filling.",
    image: `url(${chickendosa})`,
  },
  {
    category: "Non-Veg Dosa",
    foodName: "MUTTON DOSA",
    price: 14.0,
    description:
      "Savory dosa filled with seasoned mutton, creating a delicious blend of South Indian and meaty flavors.",
    image: `url(${muttondosa})`,
  },
];

export const uthappam = [
  {
    category: "Uthappam",
    foodName: "PLAIN",
    price: 11.0,
    description:
      "Thick, fluffy South Indian pancake made from fermented lentil and rice batter, often topped with vegetables.",
    image: `url(${plainutthapam})`,
  },
  {
    category: "Uthappam",
    foodName: "ONION",
    price: 12.0,
    description:
      "Thick South Indian pancake topped with onions, cooked until golden-brown, offering a flavorful and satisfying meal.",
    image: `url(${onionutthapam})`,
  },
  {
    category: "Uthappam",
    foodName: "VEG",
    price: 12.0,
    description:
      "Thick South Indian pancake topped with vegetables, a flavorful and satisfying dish.",
    image: `url(${vegutthapam})`,
  },
];

export const southIndianDelicacies = [
  {
    category: "South Indian Delicacies",
    foodName: "IDLY(2 PIECES)",
    price: 7.0,
    description:
      "Steamed rice and lentil cake, a staple South Indian breakfast, served with chutney and sambar.",
    image: `url(${idly})`,
  },
  {
    category: "South Indian Delicacies",
    foodName: "SAMBAR IDLY",
    price: 9.0,
    description:
      "Steamed rice cakes soaked in lentil-based vegetable stew, a classic South Indian breakfast dish.",
    image: `url(${sambaridly})`,
  },
  {
    category: "South Indian Delicacies",
    foodName: "MINI GHEE IDLY",
    price: 9.0,
    description:
      "Small, spongy steamed rice cakes, enriched with ghee, a delightful South Indian bite-sized delicacy.",
    image: `url(${minigheeidly})`,
  },
  {
    category: "South Indian Delicacies",
    foodName: "CHENNAI PODI IDLY",
    price: 9.0,
    description:
      "Traditional South Indian dish where soft idlis are coated with flavorful spice powder made from lentils and served with chutney.",
    image: `url(${chennaipodiidly})`,
  },
  {
    category: "South Indian Delicacies",
    foodName: "GHEE PONGAL",
    price: 11.0,
    description:
      "Savory South Indian rice dish cooked with ghee, lentils, and spices, offering rich and comforting flavors.",
    image: `url(${gheepongal})`,
  },
  {
    category: "South Indian Delicacies",
    foodName: "IDLY VADA COMBO",
    price: 10.0,
    description:
      "Popular South Indian breakfast featuring soft rice cakes (idlis) and crispy lentil fritters (vadas).",
    image: `url(${idlyvadacombo})`,
  },
  {
    category: "South Indian Delicacies",
    foodName: "PONGAL VADA COMBO",
    price: 10.0,
    description:
      "A delightful South Indian breakfast pairing creamy rice and lentil dish (pongal) with crispy lentil fritters (vadas).",
    image: `url(${pongalvadacombo})`,
  },
];
