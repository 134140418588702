import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import googleplaystore from "../assets/images/GooglePlayStore.png";
import appleplaystore from "../assets/images/ApplePlayStore.png";



const Footer = () => {
  return (
    <footer className="site-footer" role="contentinfo">
      <Container fluid>
        <Row>
          <Col md={12} className="text-center">
          
            <div className="container" style={{ textAlign: "left" }}>
              <div className="row mb-5">
                <div className="col-md-4 mb-5">
                  <h3>About Us</h3>
                  <p className="mb-5">
                    "Our Indian restaurant in Bentonville, AR, brings the delicious taste of Indian dishes to you! Look around our website and if you have any comments or questions, please feel free to contact us. We hope to see you again!"
                  </p>
                  <ul className="list-unstyled footer-link d-flex footer-social">
                    <li>
                      <a href="https://www.facebook.com/cholacafeAR/" className="p-2">
                        <span className="fa fa-facebook"></span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/cholacafe.ar/?hl=en" className="p-2">
                        <span className="fa fa-instagram"></span>
                      </a>
                    </li>
                  </ul>
                <div>
                  <ul className="list-unstyled footer-link">
                    <li className="d-block">
                      <span className="d-block text-black"><strong className="text-black">Address:</strong></span>
                      <span>2502 SW 14th St, Suite 26, Bentonville, </span><br/>
                      <span>Arkansas, United States - 72712</span>
                    </li>
                    <li className="d-block">
                      <span className="d-block text-black">Phone:</span>
                      <span>+1 (479) 553-7249</span>
                    </li>
                    <li className="d-block">
                      <span className="d-block text-black">Email:</span>
                      <span>CholaCafe@yahoo.com</span>
                    </li>
                  </ul>
                </div>          

                </div>
                <div className="col-md-5 mb-5">
                  <div className="mb-5">
                    <h3>Opening Hours</h3>
                      <table>
                        <tr>
                          <td>Sunday</td><td>11:30AM-2:00PM</td>
                        </tr>
                        <tr>
                          <td></td><td>05:30PM-8:30PM</td>
                        </tr>
                        <tr>
                          <td>Monday</td><td>Closed</td>
                        </tr>
                        <tr>
                          <td>Tues, Wed, Thurs</td><td>11:30AM-2:00PM</td>
                        </tr>
                        <tr>
                          <td></td><td>05:30PM-9:00PM</td>
                        </tr>
                        <tr>
                          <td>Friday, Saturday</td><td>11:30AM-2:00PM</td>
                        </tr>
                        <tr>
                          <td></td><td>05:30PM-9:30PM</td>
                        </tr>
                      </table>                                                         
                  </div>
                  <div className="col-md-5 mb-5">
                  <h3>Download CholaCafe App & Order now on app</h3>
                  <ul className="list-unstyled footer-link">
                    <li>
                    <a href="https://play.google.com/store/apps/details?id=com.chola.cafe"><img src={googleplaystore} alt="React Image" className="img-fluid about_img_1" /></a>
                    </li>
                    <li>
                    <a href="https://apps.apple.com/in/app/chola-cafe/id6467620663"><img src={appleplaystore} alt="React Image" className="img-fluid about_img_1" /></a>
                    </li>
                  </ul>
                </div>                

                </div>
                <div className="col-md-3 mb-5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.101606696513!2d-94.24364598980547!3d36.358317692370534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c90fde5908a1cf%3A0xc6ba272b84d72250!2sChola%20cafe!5e0!3m2!1sen!2sus!4v1691113138990!5m2!1sen!2sus"
                  width="200"
                  height="200"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />

              </div>

                <div className="col-md-3"></div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <p>
                    <small className="block">
                      &copy; 2023 <strong className="text-black">Chola</strong>{" "}
                      . All Rights Reserved.
                      <br /> Design by{" "}
                      <a href="https://www.datumailabs.com/">
                        Datum AI Labs
                        </a>{' '} 
                      <br /><a href="/privacy">Privacy
                      </a>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
